import React, { useState } from 'react';
import './contact.css';
import contact from './Image/contactd.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';


function Contact() {
  
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    city: '',
    message: '',
    communications: false,
  });
  const [formErrors, setFormErrors] = useState({});

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = {};
    // Name validation
    if (!/^[A-Za-z\s]+$/.test(formData.name)) {
      errors.name = 'Name should contain only alphabets';
    }
    // Email validation
    if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = 'Invalid email address';
    } else if (!formData.email.endsWith('@gmail.com')) {
      errors.email = 'Email address should end with @gmail.com';
    }
    // Phone validation
    if (!/^\d+$/.test(formData.phone)) {
      errors.phone = 'Phone number should contain only numbers';
    }
    // City validation
    if (!/^[A-Za-z\s]+$/.test(formData.city)) {
      errors.city = 'City should contain only alphabets';
    }
    setFormErrors(errors);
    // If there are no errors, you can submit the form
    if (Object.keys(errors).length === 0) {
      // Perform form submission
      console.log('Form submitted:', formData);
      // Send the form data via fetch
      await fetch('http://localhost:4000/submitform', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      alert('Form submitted successfully!');
      setFormData({
        name: '',
        email: '',
        phone: '',
        city: '',
        message: '',
        communications: false
      });
    }
  }
  
  function handleClick() {
    setShowContactInfo(!showContactInfo);
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling behavior
    });
  };

  return (
    <div className="contact-container">
      <hr className="horizontal-rule" />
      <div className='ch'>
          <p>Contact Us <span className="greater-than-symbol">{'>'}</span></p>
      </div>
      <hr className="horizontal-rule" />
      
      {/* New section with background color combination of green and sky */}
      <div className="green-sky-section">
      {/* <img src={contact} alt="Image description" className="section-image" /> */}
      <h1 className="section-heading">
        Are you ready to work smarter? We can help you !</h1>
        <img src={contact} alt="Image description" className="section-image" />
        

        <a href="" className="contact-button">Contact Us</a>
        <div>
            <h1>
               
            </h1>
        </div>
      </div>


      <div className="two-side-section">
        <div className="left-side">
          <h2>Contact a consultant</h2>
          <p>How can we help your organisation? Fill out the form to contact a consultant.</p>
          <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
  
  <label htmlFor="name">Your Name:</label>
  {formErrors.name && <p className="error-message" style={{ color: 'red', fontSize: '14px', marginLeft: '1px', marginTop:'-1px', marginBottom:'-10px' }}>{formErrors.name}</p>}
  <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
</div>

            <div className="form-group">
  <label htmlFor="email">Your Email:</label>
  {formErrors.email && <p className="error-message" style={{ color: 'red', fontSize: '14px', marginLeft: '1px', marginTop:'-1px', marginBottom:'-10px' }}>{formErrors.email}</p>}
  <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
</div>

<div className="form-group">
  <label htmlFor="phone">Your Phone Number:</label>
  {formErrors.phone && <p className="error-message" style={{ color: 'red', fontSize: '14px', marginLeft: '1px', marginTop:'-1px', marginBottom:'-10px' }}>{formErrors.phone}</p>}
  <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
</div>
<div className="form-group">
  <label htmlFor="city">Your City:</label>
  {formErrors.city && <p className="error-message" style={{ color: 'red', fontSize: '14px', marginLeft: '1px', marginTop:'-1px', marginBottom:'-10px' }}>{formErrors.city}</p>}
  <input type="text" id="city" name="city" value={formData.city} onChange={handleChange} required />
</div>
            <div className="form-group">
              <label htmlFor="message">Tell us about your needs:</label>
              <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
            </div>
            <div className="form-group">
              <div className="checkbox-wrapper">
                <input type="checkbox" id="communications" name="communications" checked={formData.communications} onChange={handleChange} required />
                <label htmlFor="communications">Yes, I would like to receive communications about products and offerings from ECG.</label>
              </div>
            </div>
            <button type="submit" className="submit-button">Submit</button>
          </form>

          <div className='lastline'>
            <hr className="custom-hr"/>
          </div>
          <div className='consultant'>
        <h2>
          <i className="fas fa-envelope" style={{ color: '#007bff', marginRight: '5px' }}></i>
          <button className="consultant-button" onClick={handleClick}>
            {showContactInfo ? 'Hide contact details' : 'Contact a consultant'}
          </button>
        </h2>
        {showContactInfo && (
          <div>
            <p><i className="fas fa-envelope" style={{ color: '#007bff', marginRight: '5px' }}></i>Email: hiring@ecgpl.com</p>
            <p><i className="fas fa-phone" style={{ color: '#007bff', marginRight: '5px' }}></i>Phone: +91 9938014469</p>
          </div>
        )}
      </div>
        </div>


        <div className="right-side">
          <div className='hrb'>
            <hr className="custom-hr"/>
            <h2>Contact Us <i className="fas fa-envelope" style={{ color: '#007bff' }}></i></h2>
            <p><i className="fas fa-envelope" style={{ color: '#007bff', marginRight: '5px' }}></i>Email: hiring@ecgpl.com</p>
            <p><i className="fas fa-phone" style={{ color: '#007bff', marginRight: '5px' }}></i>Phone: +91 9938014469</p>
            <hr className="custom-hr"/>
          </div>
          <div className='mission'>
          <h3>Our Mission</h3>
          <p>We strive to provide innovative solutions that help businesses work more efficiently and effectively. Our team is dedicated to understanding your needs and delivering tailor-made solutions to address them.</p>
          </div>
          <div className='mission1'>
          <h3>Submit an RFP</h3>
          <p>Thank you for your interest in our services. Submit a request for proposal (RFP) with any related documents, and we’ll be in touch shortly.</p>
          </div>

          <div className='mission2'>
          <h3>Careers at ECG</h3>
          <p>At ECG, we are always on the lookout for the best and the brightest. Find out more about what we have to offer at Mercer careers.</p>
          </div>

          
          
        </div>
        <button className="back-to-top-button" onClick={scrollToTop}>
      <FontAwesomeIcon icon={faArrowUp} style={{ color: 'white' }}/>
      </button>
      </div>

      
    </div>
  );
}

export default Contact;
