// Home.js
 
import React, { useState, useEffect, useRef } from "react";
import AppTestimonials from './Testimonials';
import Slider from "react-slick";
import "./Home.css";
import homev from "./Video/homev.mp4";
import photo1 from "./Image/photo1.jpg";
import photo2 from "./Image/photo2.jpg";
import photo3 from "./Image/photo3.jpg";
import dot from "./Image/dot.png";
import ERP from "./Image/ERP.jpg";
import HRMS from "./Image/HRMS.jpg";
import FUTURE from "./Image/future.jpg";
// import serviceex from './Image/serviceex.webp';
import strategy from "./Image/strategy.jpg";
import business from "./Image/business.jpg";
// import finance from './Image/finance.jpg'
// import legal from './Image/legal.jpg';
import sale from "./Image/sales.jpg";
import brand from "./Image/brand.jpg";
import marketing from "./Image/marketing.jpg";
import operation from "./Image/operation.jpg";
import management from "./Image/management.jpg";
import client1 from "./Image/client1.jpg";
import client2 from "./Image/client2.png";
import client3 from "./Image/client3.png";
import client4 from "./Image/client4.png";
import client5 from "./Image/client5.jpg";
import mesh from "./Image/mesh-grid.jpg";
// import Chat from './Chat.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
 
const Home = () => {
  const [runningNumbers, setRunningNumbers] = useState({
    section1: 0,
    section2: 0,
    section3: 0,
    section4: 0,
  });
 
  useEffect(() => {
    const maxCounts = {
      section1: 15,
      section2: 100,
      section3: 200,
      section4: 20,
    };
    const maxDuration = Math.max(...Object.values(maxCounts));
    const intervalTime = Math.floor(8 / maxDuration);
 
    const interval = setInterval(() => {
      setRunningNumbers((prevNumbers) => {
        const updatedNumbers = {
          section1:
            prevNumbers.section1 < maxCounts.section1
              ? prevNumbers.section1 + 1
              : maxCounts.section1,
          section2:
            prevNumbers.section2 < maxCounts.section2
              ? prevNumbers.section2 + 1
              : maxCounts.section2,
          section3:
            prevNumbers.section3 < maxCounts.section3
              ? prevNumbers.section3 + 1
              : maxCounts.section3,
          section4:
            prevNumbers.section4 < maxCounts.section4
              ? prevNumbers.section4 + 1
              : maxCounts.section4,
        };
 
        // Check if all sections have reached their maximum count
        const allSectionsStopped =
          updatedNumbers.section1 === maxCounts.section1 &&
          updatedNumbers.section2 === maxCounts.section2 &&
          updatedNumbers.section3 === maxCounts.section3 &&
          updatedNumbers.section4 === maxCounts.section4;
 
        // If all sections have stopped, clear the interval
        if (allSectionsStopped) {
          clearInterval(interval);
        }
 
        return updatedNumbers;
      });
    }, intervalTime);
 
    return () => clearInterval(interval);
  }, []);
 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling behavior
    });
  };
 
  const redBackgroundSectionRef = useRef(null);
 
  useEffect(() => {
    const redBackgroundSection = redBackgroundSectionRef.current;
 
    const handleScroll = () => {
      const sectionTop = redBackgroundSection.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
 
      const threshold = 0.5; // Adjust this value to set the scroll threshold
 
      if (sectionTop - scrollTop < windowHeight * threshold) {
        redBackgroundSection.classList.add("visible");
        window.removeEventListener("scroll", handleScroll);
      }
    };
 
    window.addEventListener("scroll", handleScroll);
 
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
 
  const services = [
    {
      id: 1,
      name: "Strategy Consultance",
      photo: strategy,
      description:
        "See how we’ve helped ambitious clients achieve extraordinary outcomes.",
      section1: {
        salesTriumphs:
          "Sales Triumphs: Redefining Standards, Igniting Client Brilliance!",
      },
      section2: {
        heading: "Tailored Strategies",
        boxId: "box-1",
        solutionContent:
          "Recognizing the uniqueness of each business,we create customized strategies tailored to your goals and objectives,drawing from our extensive industry expertise.",
      },
    },
    {
      id: 2,
      name: "Business Consultance",
      photo: business,
      description:
        "See how we’ve helped ambitious clients achieve extraordinary outcomes.",
      section1: {
        salesTriumphs:
          "Sales Triumphs: Redefining Standards, Igniting Client Brilliance!",
      },
      section2: {
        heading: "Grow With Us",
        boxId: "box-1",
        solutionContent:
          "Unlock growth opportunities with our expert guidance. From market entry to product launches, we are with you every step of the way.",
      },
    },
    {
      id: 3,
      name: "Financial Consultance",
      photo: management,
      description:
        "See how we’ve helped ambitious clients achieve extraordinary outcomes.",
      section1: {
        salesTriumphs:
          "Sales Triumphs: Redefining Standards, Igniting Client Brilliance!",
      },
      section2: {
        heading: "Finance Mastery",
        boxId: "box-1",
        solutionContent:
          "Our financial experts provide strategic guidance in planning,budgeting, and decision-making for stability and growth.",
      },
    },
    {
      id: 4,
      name: "Legal Consultance",
      photo: brand,
      description:
        "See how we’ve helped ambitious clients achieve extraordinary outcomes.",
      section1: {
        salesTriumphs:
          "Sales Triumphs: Redefining Standards, Igniting Client Brilliance!",
      },
      section2: {
        heading: "Regulatory Advice",
        boxId: "box-1",
        solutionContent:
          "We provide regulatory advice and support, ensuring compliance with evolving regulations and government requirements.",
      },
    },
    {
      id: 5,
      name: "Sales Consultance",
      photo: sale,
      description:
        "See how we’ve helped ambitious clients achieve extraordinary outcomes.",
      section1: {
        salesTriumphs:
          "Sales Triumphs: Redefining Standards, Igniting Client Brilliance!",
      },
      section2: {
        heading: "Sales Strategy",
        boxId: "box-1",
        solutionContent:
          "We develop tailored sales strategies through  in-depth analysis, aligning with your goals for success.",
      },
    },
    {
      id: 6,
      name: "Brand Consultance",
      photo: brand,
      description:
        "See how we’ve helped ambitious clients achieve extraordinary  outcomes.",
      section1: {
        salesTriumphs:
          "Sales Triumphs: Redefining Standards, Igniting Client Brilliance!",
      },
      section2: {
        heading: "Brand Expansion",
        boxId: "box-1",
        solutionContent:
          "We utilize our expertise in brand architecture and licensing agreements to ensure a smooth transition into new markets and product lines.",
      },
    },
    {
      id: 7,
      name: "Marketing Consultance",
      photo: marketing,
      description:
        "See how we’ve helped ambitious clients achieve extraordinary outcomes.",
      section1: {
        salesTriumphs:
          "Sales Triumphs: Redefining Standards, Igniting Client Brilliance!",
      },
      section2: {
        heading: "Marketing Prowess",
        boxId: "box-1",
        solutionContent:
          "We optimize your online presence for maximum impact through SEO, PPC, social media, and content marketing.",
      },
    },
    {
      id: 8,
      name: "Operation Consultance",
      photo: operation,
      description:
        "See how we’ve helped ambitious clients achieve extraordinary  outcomes.",
      section1: {
        salesTriumphs:
          "Sales Triumphs: Redefining Standards, Igniting Client Brilliance!",
      },
      section2: {
        heading: "Efficient Operation",
        boxId: "box-1",
        solutionContent:
          "We analyze current workflows, streamline processes, and optimize resource allocation to reduce costs.",
      },
    },
    {
      id: 9,
      name: "Management Consultance",
      photo: management,
      description:
        "See how we’ve helped ambitious clients achieve extraordinary  outcomes.",
      section1: {
        salesTriumphs:
          "Sales Triumphs: Redefining Standards, Igniting Client Brilliance!",
      },
      section2: {
        heading: "Org. Evolution",
        boxId: "box-1",
        solutionContent:
          "We provide tailored solutions, including leadership development and change management strategies, to drive  organizational agility and innovation.",
      },
    },
    // Add more services as needed
  ];
 
  const serviceSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
 
  const clientLogos = [client1, client2, client3, client4, client5]; // Add more client images as needed
 
  // Settings for client logos slider
  const clientSettings = {
    dots: false,
    infinite: true,
    speed: 4000, // Adjust speed as needed
    slidesToShow: 3, // Show three clients at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Set to 0 for smooth continuous scrolling
    cssEase: "linear", // Linear scrolling
    pauseOnHover: false, // Continue scrolling even on hover
    pauseOnFocus: false, // Continue scrolling even when focused
  };


  if (window.matchMedia("(max-width: 320px)").matches) {
    clientSettings.slidesToShow = 2;
  }
 
  // const handleScheduledCallClick = () => {
 
  //   window.location.href = '/contact';
  // };
 
  // const handleLearnAboutUsClick = () => {
  //   // Navigate to the learn about us page
  //   window.location.href = '/about';
  // };
 
  return (
    <div>
      <div className="container">
        <div className="full-screen video-container">
          <video autoPlay loop muted playsInline>
            <source src={homev} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
 
          <div className="color-overlay">
            <div className="social-icons">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-link"
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  className="social-icon facebook"
                  style={{ color: "#1877f2", padding: "10px" }}
                />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-link"
              >
                <FontAwesomeIcon
                  icon={faTwitter}
                  className="social-icon twitter"
                  style={{ color: "#1da1f2", padding: "10px" }}
                />
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-link"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="social-icon instagram"
                  style={{ color: "#c13584", padding: "10px" }}
                />
              </a>
              <a
                href="https://www.linkedin.com/in/ecg-precision-consulting-2069302b4/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon-link"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="social-icon linkedin"
                  style={{ color: "#0077B5", padding: "10px" }}
                />
              </a>
            </div>
            <h1 className="video-heading">
              We are a growth and <br />
              <span className="transformation">transformation</span>
              <br />
              <span className="firm">consulting firm</span>{" "}
            </h1>
            <div className="overlay-button">
              <div className="arb">
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
          </div>
        </div>
      </div>
 
      <div className="additional-section">
        <img src={dot} alt="additional" className="dot-image" />
        <div className="additional-text">
          <h1>WHO WE ARE</h1>
        </div>
        <div className="bar"></div>
        <div className="additional-paragraph">
          <p>
            At ECG, our seasoned team is dedicated to driving sustainable growth
            with innovative solutions. With expertise in emerging technologies,
            we collaborate to seize opportunities and pave the path to mutual
            success, ensuring lasting prosperity for all involved.
          </p>
        </div>
        <button className="read-more-button">
          <a href="/about">
            <span className="arrow">&rarr;</span> READ MORE
          </a>
        </button>
      </div>
     
     
       <div className="growth-section">
        <h1> CREATING </h1>
        <h1> NEXT LEVEL </h1>
        <h1> GROWTH </h1>
 
        <div className="growth-content">
          <div className="photo-container">
            <div className="photo-item ">
              <img src={HRMS} alt="Photo 1" />
              <h3 className="photo-text">Empower Your Business Success</h3>
            </div>
            <div className="photo-item ">
              <img src={ERP} alt="Photo 2" />
              <h3 className="photo-text">
                Transform Your Business <br />
                with Strategic Solutions
              </h3>
            </div>
            <div className="photo-item ">
              <img src={FUTURE} alt="Photo 3" />
              <h3 className="photo-text">
                Crafting a Visionary
                <br /> Business Future
              </h3>
            </div>
          </div>
        </div>
      </div>
 
      <div className="red-background-container">
        <div
          ref={redBackgroundSectionRef}
          id="red-background-section"
          className="red-background-section"
        >
          <div className="notification-box">
            <p>Bridging people and technology, so the nation dreams bigger</p>
          </div>
          <h2>
            When we aspire, we inspire and
            <br /> what we dream, we can achieve -<br /> for a stronger
            tomorrow!
          </h2>
        </div>
      </div>
 
      <div className="services-section">
        {/* <h2>Our Services</h2> */}
        <Slider {...serviceSettings}>
          {services.map((service) => (
            <div
              key={service.id}
              className={`service-item service-${service.id}`}
            >
              <img src={service.photo} alt={service.name} />
              <div className="service-text">
                <h3>{service.name}</h3>
                <p dangerouslySetInnerHTML={{ __html: service.description }} />
 
                <div className="sections-container">
                  {/* Section 1 */}
                  <div className="section section-1">
                    <h5>Featured client success story</h5>
                    <h4>
                      {service.section1 ? service.section1.salesTriumphs : ""}
                    </h4>
                  </div>
 
                  {/* Section 2 */}
                  <div className="section section-2">
                    <h5>How we helped</h5>
                    <div className="box" id="box-2">
                      <h5>
                        {service.section2 && service.section2.heading
                          ? service.section2.heading
                          : ""}
                      </h5>
                      <p
                        className="hidden-paragraph"
                        dangerouslySetInnerHTML={{
                          __html: ` ${
                            service.section2
                              ? service.section2.solutionContent
                              : ""
                          }`,
                        }}
                      />
 
                      <button className="view-solution-button">
                        View Solution
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
 
      <div className="other-section">
        <h2>We renew our commitment to this future through…</h2>
 
        {/* Add 3 sections with photos */}
        <div className="photo-section">
          <img src={photo1} alt="hi" />
          <h1>Build Strategies</h1>
          <p>Crafting plans for success in various domains.</p>
        </div>
        <div className="photo-section">
          <img src={photo2} alt="hi" />
          <h1>Build Confidence</h1>
          <p> Empowering individuals through self-assurance.</p>
        </div>
        <div className="photo-section">
          <img src={photo3} alt="hi" />
          <h1>Build Your Business</h1>
          <p> Elevate your enterprise with strategic growth</p>
        </div>
      </div>
      <AppTestimonials/>
      <div>
        <div className="sections-wrappers">
          <div className="section-containers">
            <div className="running-number-items">
              {runningNumbers.section1}+
            </div>
            <div className="sections">
              <h2>Employee</h2>
            </div>
          </div>
 
          <div className="section-container">
            <div className="running-number-items">
              {runningNumbers.section2}+
            </div>
            <div className="sections">
              <h2>Awards</h2>
            </div>
          </div>
 
          <div className="section-container">
            <div className="running-number-items">
              {runningNumbers.section3}+
            </div>
            <div className="sections">
              <h2>Project done</h2>
            </div>
          </div>
 
          <div className="section-container">
            <div className="running-number-items">
              {runningNumbers.section4}+
            </div>
            <div className="sections">
              <h2>happy clients</h2>
            </div>
          </div>
        </div>
        {/* <hr className="horizontal-bar" /> */}
      </div>
 
      <div className="new-section">
        <img src={mesh} alt="New Section Image" />
        <div className="green-bar"></div>
      </div>
      <div className="new-section1">
        <div className="section-left">
          <h2>
            Talk to us about
            <br />
            your project
          </h2>
          <p>
            Do not hesitate to get in touch with us! Whether you have a project
            <br />
            in mind or simply want to learn more about our services,
            <br />
            our team is ready to help.
          </p>
        </div>
        <div className="section-right">
          <a href="/contact">
            <button className="scheduled-call-button">Scheduled Call</button>
          </a>
          <a href="/about">
            <button className="learn-about-us-button">Learn About Us</button>
          </a>
        </div>
      </div>
      <div className="client-logos-section">
        <div className="client-logos-container">
          <Slider {...clientSettings}>
            {clientLogos.map((logo, index) => (
              <div key={index} className="client-logo-item">
                <img src={logo} alt={`Client ${index + 1}`} />
              </div>
            ))}
          </Slider>
        </div>
 
        {/* <div className="sticky-background-section" >
        <div className="contact-info">
          <h2>Contact Us</h2>
          <p>Feel free to get in touch with us for any inquiries or collaboration opportunities.</p>
        </div>
        <div className="contact-form">
          <form>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone:</label>
              <input type="tel" id="phone" name="phone" />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message"></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
 
 
          <hr className="horizontal-bar" />
          <div>
     
 
      <div className="sections-wrapper">
      <div className="section-container">
        <div className="running-number-item">
          {runningNumbers.section1}+
        </div>
        <div className="sections">
        <h2>Employee</h2>
        </div>
      </div>
 
      <div className="section-container">
        <div className="running-number-item">
          {runningNumbers.section2}+
        </div>
        <div className="sections">
        <h2>Awards</h2>
        </div>
      </div>
 
      <div className="section-container">
        <div className="running-number-item">
          {runningNumbers.section3}+
        </div>
        <div className="sections">
        <h2>Project done</h2>
        </div>
      </div>
 
      <div className="section-container">
        <div className="running-number-item">
          {runningNumbers.section4}+
        </div>
        <div className="sections">
        <h2>happy clients</h2>
        </div>
      </div>
    </div>
     
    </div>
      </div>
      </div>  */}
 
        <button className="back-to-top-button" onClick={scrollToTop}>
          <FontAwesomeIcon icon={faArrowUp} style={{ color: "white" }} />
        </button>
 
        {/* <Chat /> */}
      </div>
    </div>
  );
};
 
export default Home;