// Navbar.js

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './Navbar.css';
import logo from './Image/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isAboutUsDropdownOpen, setIsAboutUsDropdownOpen] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const aboutUsDropdownRef = useRef(null);
  const servicesDropdownRef = useRef(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleAboutUsHover = () => {
    setIsAboutUsDropdownOpen(true);
    setIsServicesDropdownOpen(false);
  };

  const handleServicesHover = () => {
    setIsServicesDropdownOpen(true);
    setIsAboutUsDropdownOpen(false);
  };

  const closeDropdown = (event) => {
    if (
      aboutUsDropdownRef.current &&
      !aboutUsDropdownRef.current.contains(event.target)
    ) {
      setIsAboutUsDropdownOpen(false);
    }

    if (
      servicesDropdownRef.current &&
      !servicesDropdownRef.current.contains(event.target)
    ) {
      setIsServicesDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeDropdown);

    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* Logo */}
        <Link to="/" className="navbar-logo">
          <img src={logo} alt="Logo" />
        </Link>

        {/* Navigation Menu */}
        <ul className={`nav-menu ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
          <li className="nav-item">
            <Link to="/" className="nav-link">
              Home
            </Link>
          </li>
          <li className="nav-item" ref={aboutUsDropdownRef}>
            <div
              className="dropdown"
              onMouseEnter={handleAboutUsHover}
              onMouseLeave={() => setIsAboutUsDropdownOpen(false)}
            >
              <span className="nav-link">Who we are</span>
              <div className={`dropdown-content ${isAboutUsDropdownOpen ? 'show' : ''}`}>
                <Link to="/about">About Us</Link>
                {/* <Link to="/careers">What we believe?</Link> */}
              </div>
              <FontAwesomeIcon icon={faAngleDown} className="dropdown-icon" />
            </div>
          </li>
          
          <li className="nav-item" ref={servicesDropdownRef}>
  <div
    className="dropdown"
    onMouseEnter={handleServicesHover}
    onMouseLeave={() => setIsServicesDropdownOpen(false)}
  >
    <a  className="nav-link">Services</a>
    <div className={`services-dropdown-content ${isServicesDropdownOpen ? 'show' : ''}`} onMouseEnter={() => setIsServicesDropdownOpen(true)} onMouseLeave={() => setIsServicesDropdownOpen(false)}>
      {/* Updated Links for Services */}
      <Link to="/services/strategy">Strategy Solution</Link>
      <Link to="/services/business">Business Solution</Link>
      <Link to="/services/finance">Financial Solution</Link>
      <Link to="/services/legal">Legal Solution</Link>
      <Link to="/services/sales">Sales Solution</Link>
      <Link to="/services/brand">Brand Solution</Link>
      <Link to="/services/marketing">Marketing Solution</Link>
      <Link to="/services/operation">Operation Solution</Link>
      <Link to="/services/management">Management Solution</Link>
    </div>
    <FontAwesomeIcon icon={faAngleDown} className="dropdown-icon" />
  </div>
</li>
          <li className="nav-item">
            <Link to="/contact" className="nav-link">
              Contact Us
            </Link>
          </li>
        </ul>

        {/* Search Box */}
        <div className="search-box">
          <input type="text" placeholder="Search" />
          <button>
            <i className="fas fa-search"></i>
          </button>
        </div>

        {/* Responsive Menu Button */}
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          <i className={isMobileMenuOpen ? '' : ''}></i>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
