import React from 'react'
import './marketing.css';
import About from './images/operation.jpg';
import start from './images/operations.jpg'
import iot from './images/process.png'
import competetior from './images/Tech.png'
import resource from './images/resource.png'
import plan from './images/chain.png'
import perform from './images/quality.png'
import risk from './images/risk.png'
import SliderService from '../SliderService';


function operation() {
  return (
    <div>
        <div className='strategy'>
      <img src={About} alt="additional" className="strategyi" />
      <div className="brand-layer">
     
          {/* Content for the upper layer */}
          <h1>Operational Solutions</h1>
          <h4>Strategic Operations Consulting: Driving Organizational Success</h4>
          <p>This section delves into Operations Consulting for businesses to adapt to market shifts, anticipate trends,<br /> and capitalize on opportunities, ensuring long-term sustainability and growth.</p>
        </div>
      </div>
      <div className='solution'>

      <div className="solution-content">
          <div className="solution-text">
            <h2><span className="line"></span><span className="dot"></span>Operational Solutions</h2>
            <h3>Comprehensive Operational Solutions for Business Growth and Sustainability</h3>
            <p>
            In today&lsquo;s dynamic business landscape, achieving sustainable growth requires more than just sound strategy; it demands comprehensive operational solutions that optimize efficiency and foster resilience.
             By understanding the unique needs of each business, our consultancy conducts thorough assessments to pinpoint inefficiencies and devise tailored strategies aligned with overarching objectives. Through strategic planning, we establish clear goals and performance metrics, guiding the implementation of streamlined processes and resource management techniques aimed at maximizing efficiency and minimizing waste.
             We prioritize sustainability, integrating eco-friendly practices to minimize environmental impact and enhance social responsibility.
            </p>
          </div>
          <div className="solution-image">
          <img src={start} alt="additional"  />
          </div>
        </div>

      </div>

      <div className='next'>
      <h2><span className='hr'><hr /></span>Our Solution <span className='hra'><hr /></span></h2>
      <div className='extended'>
      <h3>Extended Solutions for Endless possibilities</h3>
      </div>
      
      <div className='excontent'>
      <div className="ex-item">
      <img src={iot} alt="Image 1" className="section-images" />
      <h5>Process Optimization</h5>
      <div className='para'>
      <p>Conduct a thorough and comprehensive review of current processes and workflows to meticulously identify inefficiencies and areas for improvement.</p>
      <span className='hrm'><hr /></span>
      </div>
      
    </div>
    <div className="ex-item">
    <img src={competetior} alt="Image 1" className="section-images" />
      <h5>Technology Integration</h5>
      <div className='paras'>
      <p>Utilize ERP systems, automation tools, and analytics platforms to streamline operations, integrate systems for seamless data flow, and enhance organizational visibility.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={resource} alt="Image 1" className="section-images" />
      <h5>Resource Allocation</h5>
      <div className='parass'>
      <p>Optimize resource allocation to minimize bottlenecks, enhance efficiency, and ensure smooth operations by aligning capacity with demand.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
      </div>

      <div className='excontent'>
      <div className="ex-item">
      <img src={plan} alt="Image 1" className="section-images" />
      <h5>Supply Chain Management</h5>
      <div className='para'>
      <p>Strengthen supply chain through enhanced supplier collaboration, optimized inventory, and robust logistics, utilizing forecasting and demand planning for effective disruption mitigation.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={perform} alt="Image 1" className="section-images" />
      <h5>Quality Control Measures</h5>
      <div className='paras'>
      <p>Implement rigorous quality control measures to meet or exceed customer expectations. Establish standards, conduct inspections, and invest in training for quality assurance.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={risk} alt="Image 1" className="section-images" />
      <h5>Risk Management</h5>
      <div className='parass'>
      <p>Risk management is crucial for identifying and mitigating potential threats to organizational objectives. By analyzing uncertainties and implementing strategies, negative outcomes are minimized.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
      </div>

      </div>
      <SliderService/>

      <div className='con' >
    <h2>Embark on Your Connected Journey</h2>
    <p>Embark on your connected journey with ECG, blending innovation and strategy. We specialize in guiding organizations through digital transformation, crafting robust strategies, and implementing cutting-edge solutions. Let us empower you to thrive in the evolving connectivity landscape, unlock opportunities, streamline operations, and drive sustainable growth. Join us for transformative success and redefine your business journey.</p>
    <button className="read-more">
  <a href="/contact">
    <span className="arrow">&rarr;</span>  GET STARTED
  </a>
</button>
      </div>
    </div>
  )
}

export default operation