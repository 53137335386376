import React from 'react'
import About from './images/legal.jpg';
import start from './images/law.jpg'
import './legal.css';
import iot from './images/regulatory.png'
import competetior from './images/draft.png'
import resource from './images/risk.png'
import plan from './images/property.png'
import perform from './images/dispute.png'
import risk from './images/training.png'
import SliderService from '../SliderService';

function legal() {
  return (
    <div>
         <div className='strategy'>
      <img src={About} alt="additional" className="strategyi" />
      <div className="strategy-layer">
     
          {/* Content for the upper layer */}
          <h1>Legal Solutions</h1>
          <h4>Legal Excellence: Navigating Complexities with Expert Guidance</h4>
          <p>This section delves into legal strategies for businesses to adapt to market shifts, anticipate trends,<br /> and capitalize on opportunities, ensuring long-term sustainability and growth.</p>
        </div>
      </div>
      <div className='solution'>

      <div className="solution-content">
          <div className="solution-text">
            <h2><span className="line"></span><span className="dot"></span>Legal Solutions</h2>
            <h3>Navigating Regulatory Compliance: Ensuring Adherence to Legal Standards</h3>
            <p>
            In today&apos;s complex business environment, regulatory compliance has become a critical aspect of operations for organizations across all industries. Navigating the myriad of laws, regulations, and standards can be daunting, but it is essential to ensure adherence to legal requirements.
             At ECG, we specialize in guiding businesses through the intricacies of regulatory compliance, providing expert advice and practical solutions to help them meet their legal obligations effectively.
             Our team of legal experts brings a wealth of experience and expertise in navigating the intricate web of regulations that govern various industries.
            </p>
          </div>
          <div className="solution-image">
          <img src={start} alt="additional"  />
          </div>
        </div>

      </div>

      <div className='next'>
      <h2><span className='hr'><hr /></span>Our Solution <span className='hra'><hr /></span></h2>
      <div className='extended'>
      <h3>Extended Solutions for Endless possibilities</h3>
      </div>
      
      <div className='excontent'>
      <div className="ex-item">
      <img src={iot} alt="Image 1" className="section-images" />
      <h5>Regulatory Compliance</h5>
      <div className='para'>
      <p>Ensure compliance with all relevant laws, regulations, and industry standards applicable to the organization&apos;s operations, including healthcare regulations.</p>
      <span className='hrm'><hr /></span>
      </div>
      
    </div>
    <div className="ex-item">
    <img src={competetior} alt="Image 1" className="section-images" />
      <h5>Contract Review and Drafting</h5>
      <div className='paras'>
      <p>Conduct reviews of contracts, agreements, to ensure compliance. Draft new contracts or amend existing ones to protect the organization&apos;s interests.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={resource} alt="Image 1" className="section-images" />
      <h5>Risk Management</h5>
      <div className='parass'>
      <p>
Identify potential legal risks associated with the organization&apos;s activities. Develop risk management strategies to minimize exposure and protect the consultancy.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
      </div>

      <div className='excontent'>
      <div className="ex-item">
      <img src={plan} alt="Image 1" className="section-images" />
      <h5>Intellectual Property Protection</h5>
      <div className='para'>
      <p>Protect the organization&apos;s intellectual property assets through appropriate legal mechanisms. Enforce rights and take legal action against infringement or unauthorized use.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={perform} alt="Image 1" className="section-images" />
      <h5>Dispute Resolution</h5>
      <div className='paras'>
      <p>Implement dispute resolution mechanisms to resolve conflicts and legal disputes efficiently. Engage legal counsel to represent the organization&apos;s interests and achieve favorable outcomes.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={risk} alt="Image 1" className="section-images" />
      <h5>Compliance Training / Education</h5>
      <div className='parass'>
      <p>

      Provide ongoing training to staff members on legal issues, compliance requirements, and ethical standards. Foster a culture of legal awareness within the consultancy to prevent violations.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
      </div>

      </div>
      <SliderService/>

      <div className='con' >
    <h2>Embark on Your Connected Journey</h2>
    <p>Embark on your connected journey with ECG, blending innovation and strategy. We specialize in guiding organizations through digital transformation, crafting robust strategies, and implementing cutting-edge solutions. Let us empower you to thrive in the evolving connectivity landscape, unlock opportunities, streamline operations, and drive sustainable growth. Join us for transformative success and redefine your business journey.</p>
    <button className="read-more">
  <a href="/contact">
    <span className="arrow">&rarr;</span>  GET STARTED
  </a>
</button>
      </div>
    </div>
  )
}

export default legal