import React from 'react'
import './management.css';
import About from './images/management.jpg';
import start from './images/managements.jpg'
import iot from './images/planing.png'
import competetior from './images/leader.png'
import resource from './images/performance.png'
import plan from './images/employment.png'
import perform from './images/change.png'
import risk from './images/improve.png'
import SliderService from '../SliderService';

function management() {
  return (
    <div>
         <div className='strategy'>
      <img src={About} alt="additional" className="strategyi" />
      <div className="brand-layer">
     
          {/* Content for the upper layer */}
          <h1>Management Solutions</h1>
          <h4>Assessing the Effectiveness and ROI of Implemented Strategies</h4>
          <p>This section delves into management solution&apos;s for businesses to adapt to market shifts, anticipate trends,<br /> and capitalize on opportunities, ensuring long-term sustainability and growth.</p>
        </div>
      </div>
      <div className='solution'>

      <div className="solution-content">
          <div className="solution-text">
            <h2><span className="line"></span><span className="dot"></span>Management Solutions</h2>
            <h3>Comprehensive Management Solutions for Business Growth and Sustainability</h3>
            <p>
            Comprehensive management solutions are essential for fostering business growth and ensuring long-term sustainability in today&apos;s dynamic market landscape.
             These solutions encompass a holistic approach to addressing various aspects of organizational management, including strategic planning, resource allocation, operational efficiency, and talent management.
             By integrating diverse strategies and methodologies, businesses can effectively navigate challenges, capitalize on opportunities, and adapt to evolving market trends.
            </p>
          </div>
          <div className="solution-image">
          <img src={start} alt="additional"  />
          </div>
        </div>

      </div>

      <div className='next'>
      <h2><span className='hr'><hr /></span>Our Solution <span className='hra'><hr /></span></h2>
      <div className='extended'>
      <h3>Extended Solutions for Endless possibilities</h3>
      </div>
      
      <div className='excontent'>
      <div className="ex-item">
      <img src={iot} alt="Image 1" className="section-images" />
      <h5>Strategic Planning</h5>
      <div className='para'>
      <p>Develop a strategic plan outlining mission, vision, and objectives. Align with values and stakeholders interests for organizational buy-in and commitment.</p>
      <span className='hrm'><hr /></span>
      </div>
      
    </div>
    <div className="ex-item">
    <img src={competetior} alt="Image 1" className="section-images" />
      <h5>Effective Leadership</h5>
      <div className='paras'>
      <p>Empower leaders to guide teams towards strategic goals. Invest in their development for essential skills like communication and decision-making.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={resource} alt="Image 1" className="section-images" />
      <h5>Performance Management</h5>
      <div className='parass'>
      <p>Implement a robust performance management system to set expectations, provide feedback, and align goals for accountability and results.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
      </div>

      <div className='excontent'>
      <div className="ex-item">
      <img src={plan} alt="Image 1" className="section-images" />
      <h5>Talent & Succession Planning</h5>
      <div className='para'>
      <p>Invest in talent development for all employees. Identify high-potential individuals and create tailored plans for future leadership roles through effective succession planning strategies.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={perform} alt="Image 1" className="section-images" />
      <h5>Change Management</h5>
      <div className='paras'>
      <p>Proactively manage organizational change through effective communication, stakeholder involvement, and addressing resistance, minimizing disruption and facilitating smooth transitions.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={risk} alt="Image 1" className="section-images" />
      <h5>Continuous Improvement</h5>
      <div className='parass'>
      <p>
Foster continuous improvement through innovation, collaboration, and learning. Implement feedback mechanisms, recognize innovative ideas, and establish improvement processes.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
      </div>

      </div>
      <SliderService/>

      <div className='con' >
    <h2>Embark on Your Connected Journey</h2>
    <p>Embark on your connected journey with ECG, blending innovation and strategy. We specialize in guiding organizations through digital transformation, crafting robust strategies, and implementing cutting-edge solutions. Let us empower you to thrive in the evolving connectivity landscape, unlock opportunities, streamline operations, and drive sustainable growth. Join us for transformative success and redefine your business journey.</p>
    <button className="read-more">
  <a href="/contact">
    <span className="arrow">&rarr;</span>  GET STARTED
  </a>
</button>
      </div>
    </div>
  )
}

export default management