import React from "react";
import './ServiceSlider.css';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import strategy from './Image/strategy.jpg';
import business from './Image/business.jpg';
import sale from './Image/sales.jpg';
import brand from './Image/brand.jpg';
import marketing from './Image/marketing.jpg';
import operation from './Image/operation.jpg';
import management from './Image/management.jpg';
import ERP from './Image/ERP.jpg';
import FUTURE from './Image/future.jpg';
import { Link } from 'react-router-dom';

function SliderService() {
  const settings = {
    dots: false, //dots are not showing 
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  window.scrollTo({
    top: 0,
    behavior:'smooth'
  });

  return (
    <div className="Services">
      <h2><span className='hr'><hr /></span>Our Services <span className='hra'><hr /></span></h2>
      <div className="photoContainer">
        <Slider {...settings}>
          <div className="photoItem">
            <Link to="/services/strategy">
              <img src={strategy} alt="photo1" />
              <h3 className="photoText">Strategy solution</h3>
            </Link>
          </div>

          <div className="photoItem">
            <Link to="/services/business">
              <img src={business} alt="photo2" />
              <h3 className="photoText">Business solution</h3>
            </Link>
          </div>

          <div className="photoItem">
            <Link to="/services/sales">
            <img src={sale} alt="photo3" />
            <h3 className="photoText">Sales solution</h3>
            </Link>
          </div>

          <div className="photoItem">
            <Link to="/services/brand">
            <img src={brand} alt="photo4" />
            <h3 className="photoText">Brand solution</h3>
            </Link>
          </div>

          <div className="photoItem">
            <Link to="/services/marketing">
            <img src={marketing} alt="photo5" />
            <h3 className="photoText">Marketing solution</h3>
            </Link>
          </div>
         
          <div className="photoItem">
            <Link to="/services/operation">
            <img src={operation} alt="photo6" />
            <h3 className="photoText">Operation solution</h3>
            </Link>
          </div>

          <div className="photoItem">
            <Link to="/services/management">
            <img src={management} alt="photo7" />
            <h3 className="photoText">Management solution</h3>
            </Link>
          </div>

          <div className="photoItem">
            <Link to="/services/finance">
            <img src={ERP} alt="photo8" />
            <h3 className="photoText">Financial solution</h3>
            </Link>
          </div>

          <div className="photoItem">
          <Link to="/services/legal">
            <img src={FUTURE} alt="photo9" />
            <h3 className="photoText">Legal solution</h3>
            </Link>
          </div>
        </Slider>
      </div>
    </div>
  );
}
export default SliderService;
