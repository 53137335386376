import React from 'react'
import './strategy.css';
import About from './images/about.jpg';
import start from './images/strategy.jpg'
import iot from './images/market.png'
import competetior from './images/competetior.png'
import plan from './images/plan.png'
import resource from './images/resource.png'
import risk from './images/risk.png'
import perform from './images/perform.png'
import SliderService from '../SliderService';



function strategy() {
  return (
    <div>
        <div className='strategy'>
      <img src={About} alt="additional" className="strategyi" />
      <div className="strategy-layer">
     
          {/* Content for the upper layer */}
          <h1>Strategy</h1>
          <h4>Strategic Approaches for Excellence</h4>
          <p>Effective strategies are the cornerstone of success in any endeavor. Whether it is in business, education,<br />  or personal growth, having a clear plan and the right tactics can make all the difference</p>
        </div>
      </div>
      <div className='solution'>

      <div className="solution-content">
          <div className="solution-text">
            <h2><span className="line"></span><span className="dot"></span>Strategic Solutions</h2>
            <h3>Comprehensive Strategic Solutions for Business Growth and Sustainability</h3>
            <p>At ECG, we specialize in providing comprehensive strategic solutions tailored to foster the growth and sustainability of your business. Our holistic approach encompasses various facets of organizational development, aimed at enhancing efficiency, maximizing profitability, and ensuring long-term success.
            We collaborate closely with your leadership team to develop robust strategic plans aligned with your business objectives. By conducting thorough market analysis and SWOT assessments, we identify opportunities for growth and formulate actionable strategies to capitalize on them.
            </p>
          </div>
          <div className="solution-image">
          <img src={start} alt="additional"  />
          </div>
        </div>

      </div>
      
      <div className='next'>
      <h2><span className='hr'><hr /></span>Our Solution <span className='hra'><hr /></span></h2>
      <div className='extended'>
      <h3>Extended Solutions for Endless possibilities</h3>
      </div>
      
      <div className='excontent'>
      <div className="ex-item">
      <img src={iot} alt="Image 1" className="section-images" />
      <h5>Market Analysis</h5>
      <div className='para'>
      <p>Conduct in-depth market research to identify trends, opportunities, and threats. Use data-driven insights for strategic decision-making and market positioning.</p>
      <span className='hrm'><hr /></span>
      </div>
      
    </div>
    <div className="ex-item">
    <img src={competetior} alt="Image 1" className="section-images" />
      <h5>Competitor Analysis</h5>
      <div className='paras'>
      <p>Assess the strengths, weaknesses, strategies, and market positioning of competitors. Identify gaps and areas for differentiation to gain a competitive advantage.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={plan} alt="Image 1" className="section-images" />
      <h5>Strategic Planning</h5>
      <div className='parass'>
      <p>Align organizational goals with a clear roadmap, defining concise strategies, action plans, and timelines to ensure effective execution and implementation.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
      </div>
      <div className='excontent'>
      <div className="ex-item">
      <img src={resource} alt="Image 1" className="section-images" />
      <h5>Resource Allocation</h5>
      <div className='para'>
      <p>Optimize resource allocation to ensure efficient utilization of budget, manpower, and other assets. Prioritize investments based on strategic priorities and expected returns.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={risk} alt="Image 1" className="section-images" />
      <h5>Risk Management</h5>
      <div className='paras'>
      <p>Risk management is crucial for identifying and mitigating potential threats to organizational objectives. By analyzing uncertainties and implementing strategies, negative outcomes are minimized.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={perform} alt="Image 1" className="section-images" />
      <h5>Performance Evaluation</h5>
      <div className='parass'>
      <p>
       Establish KPIs and relevant metrics for progress tracking, continuously review and adjust strategies using comprehensive performance data and stakeholder feedback for optimal outcomes.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
      </div>
      </div>
    <SliderService/>

      <div className='con' >
    <h2>Embark on Your Connected Journey</h2>
    <p>Embark on your connected journey with ECG, blending innovation and strategy. We specialize in guiding organizations through digital transformation, crafting robust strategies, and implementing cutting-edge solutions. Let us empower you to thrive in the evolving connectivity landscape, unlock opportunities, streamline operations, and drive sustainable growth. Join us for transformative success and redefine your business journey.</p>
    <button className="read-more">
  <a href="/contact">
    <span className="arrow">&rarr;</span>  GET STARTED
  </a>
</button>
</div>

    </div>
  )
}

export default strategy