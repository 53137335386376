import React from 'react'
import './marketing.css';
import About from './images/marketing.jpg';
import start from './images/brandsss.jpg'
import iot from './images/analysis.png'
import competetior from './images/segment.png'
import resource from './images/content.png'
import plan from './images/com.png'
import perform from './images/digital.png'
import risk from './images/measure.png'
import SliderService from '../SliderService';

function marketing() {
  return (
    <div>
        <div className='strategy'>
      <img src={About} alt="additional" className="strategyi" />
      <div className="brand-layer">
     
          {/* Content for the upper layer */}
          <h1>Marketing Solutions</h1>
          <h4>Strategic Marketing Solutions: Elevating Your Brand Presence</h4>
          <p>This section delves into marketing strategies for businesses to adapt to market shifts, anticipate trends,<br /> and capitalize on opportunities, ensuring long-term sustainability and growth.</p>
        </div>
      </div>
      <div className='solution'>

      <div className="solution-content">
          <div className="solution-text">
            <h2><span className="line"></span><span className="dot"></span>Marketing Solutions</h2>
            <h3>Comprehensive Marketing Solutions for Business Growth and Sustainability</h3>
            <p>
            In today&apos;s rapidly changing business environment, where consumer preferences shift, and new technologies emerge, the need for strategic marketing has never been more critical. At ECG, we recognize that staying ahead requires more than just keeping up with trends; it demands innovative strategies, insightful analytics, and a deep understanding of market dynamics.
             With our commitment to excellence and dedication to client success, we go beyond conventional approaches to deliver cutting-edge marketing solutions that not only drive growth but also foster enduring relationships with customers.
            </p>
          </div>
          <div className="solution-image">
          <img src={start} alt="additional"  />
          </div>
        </div>

      </div>

      <div className='next'>
      <h2><span className='hr'><hr /></span>Our Solution <span className='hra'><hr /></span></h2>
      <div className='extended'>
      <h3>Extended Solutions for Endless possibilities</h3>
      </div>
      
      <div className='excontent'>
      <div className="ex-item">
      <img src={iot} alt="Image 1" className="section-images" />
      <h5>Market Research and Analysis</h5>
      <div className='para'>
      <p>Begin with market research to understand audience, competitors, and trends. Use qualitative and quantitative methods for customer insights and market dynamics.</p>
      <span className='hrm'><hr /></span>
      </div>
      
    </div>
    <div className="ex-item">
    <img src={competetior} alt="Image 1" className="section-images" />
      <h5>Segmentation and Targeting</h5>
      <div className='paras'>
      <p>Segment audience for targeted campaigns. Tailor messaging for maximum effectiveness, addressing specific needs and interests to enhance relevance</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={resource} alt="Image 1" className="section-images" />
      <h5>Content Strategy and Creation</h5>
      <div className='parass'>
      <p>Develop a content strategy aligned with audience interests. Create engaging content across channels to attract and engage at various buyer journey stages.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
      </div>

      <div className='excontent'>
      <div className="ex-item">
      <img src={plan} alt="Image 1" className="section-images" />
      <h5>Integrated Communications</h5>
      <div className='para'>
      <p>Implement integrated marketing communications for consistency across all channels. Coordinate messaging, branding, and promotions online and offline to reinforce brand identity effectively.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={perform} alt="Image 1" className="section-images" />
      <h5>Digital Marketing Optimization</h5>
      <div className='paras'>
      <p>Invest in digital marketing channels like SEO, PPC, social media, and email to reach your target audience online. Continuously optimize campaigns for maximum reach, engagement, and conversions.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={risk} alt="Image 1" className="section-images" />
      <h5>Measurement and Analytics</h5>
      <div className='parass'>
      <p>Set clear objectives and KPIs to measure marketing effectiveness. Use analytics tools to track campaign performance, customer engagement, and ROI, refining strategies accordingly.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
      </div>

      </div>
      <SliderService/>

      <div className='con' >
    <h2>Embark on Your Connected Journey</h2>
    <p>Embark on your connected journey with ECG, blending innovation and strategy. We specialize in guiding organizations through digital transformation, crafting robust strategies, and implementing cutting-edge solutions. Let us empower you to thrive in the evolving connectivity landscape, unlock opportunities, streamline operations, and drive sustainable growth. Join us for transformative success and redefine your business journey.</p>
    <button className="read-more">
  <a href="/contact">
    <span className="arrow">&rarr;</span>  GET STARTED
  </a>
</button>
      </div>
    </div>
  )
}

export default marketing