import React, {useRef, useState}from 'react'
import About from './Image/about.jpg';
import './about.css';
import brand from './Image/brand.jpg';
 import leader1 from './Image/leader1.jpg'
 import leader2 from './Image/leader2.jpg'
 import leader3 from './Image/leader3.jpg'
 import { FaFacebook, FaLinkedin, } from 'react-icons/fa';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

function about() {
  const [openParagraph, setOpenParagraph] = useState(null);
  const whiteBoxRef = useRef(null);
  

  const toggleParagraph = (id) => {
    setOpenParagraph((prevState) => (prevState === id ? null : id));
  };

  const paragraphs = [
    {
      heading: 'Experience',
      content: 'With 3 years of industry experience, we understand business challenges and provide effective solutions.',
    },
    {
      heading: 'Tailored Solutions',
      content: 'Unique businesses, unique solutions. We offer personalized consulting, crafting solutions to maximize strengths and address challenges.',
    },
    {
      heading: 'Client-Centric Focus',
      content: 'At ECG, your success is our priority. We build long-term relationships with trust, transparency, and mutual respect.',
    },
    {
      heading: 'Innovative Solutions',
      content: 'In todays fast-paced world, we prioritize innovation to deliver cutting-edge solutions for our clients.',
    },
    {
      heading: 'Commitment to Excellence',
      content: 'Excellence is our standard, from work quality to team professionalism. We strive for excellence in all our consultancy services.',
    },
    {
      heading: 'Proven Track Record',
      content: 'Over time, we have globally partnered for remarkable success, with countless clients achieving milestones with our support.',
    },
  ];



  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling behavior
    });
  };

  return (
    <div >
      <div className='aboutu'>
      <img src={About} alt="additional" className="abouti" />
      <div className="upper-layer">
          {/* Content for the upper layer */}
          <h1>About Us</h1>
          <h4>Welcome to the ECG Private Limited.</h4>
          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Suspendisse et justo.<br /> Praesent mattis commodo augue. Aliquam ornare hendrerit augue.</p>
        </div>
      </div>
      <div className='who'>
      <div className="three-boxes-section">
  <div className="box1">
    <hr />
    <h2>Who We Are</h2>
    <p>At ECG, we specialize in driving transformative change and fostering growth for businesses. With seasoned experts and innovative strategists, we offer tailored solutions rooted in industry insights and emerging technologies. Let us help you tackle your unique challenges, seize opportunities, and pave the path to success together.</p>
  </div>
  <div className="box2">
    <hr />
    <h2>Our Mission</h2>
    <p>At ECG, our mission is to empower businesses to thrive in an ever-changing world by providing innovative solutions, actionable insights, and unwavering support. We achieve this by leveraging cutting-edge technologies, staying ahead of industry trends, and continuously evolving our methodologies to meet the evolving needs of our clients.</p>
  </div>
  <div className="box3">
    <hr />
    <h2>What We Do</h2>
    <div className="link-container"><a href="/services/business" ><i aria-hidden="true" className="fas fa-caret-right"></i> Business Solution</a></div>
    <div className="link-container"><a href="/services/finance"><i aria-hidden="true" className="fas fa-caret-right"></i> Financial Solution</a></div>
    <div className="link-container"><a href="/services/strategy"><i aria-hidden="true" className="fas fa-caret-right"></i> Strategic Solution</a></div>
    <div className="link-container"><a href="/services/legal"><i aria-hidden="true" className="fas fa-caret-right"></i> Legal Solution</a></div>
    <div className="link-container"><a href="/services/sales"><i aria-hidden="true" className="fas fa-caret-right"></i> Sales Solution</a></div>
    <div className="link-container"><a href="/services/marketing"><i aria-hidden="true" className="fas fa-caret-right"></i> Marketing Solution</a></div>
    <div className="link-container"><a href="/services/operation"><i aria-hidden="true" className="fas fa-caret-right"></i> Operation Solution</a></div>
    <div className="link-container"><a href="/services/management"><i aria-hidden="true" className="fas fa-caret-right"></i> Management Solution</a></div>
    
  </div>
</div>

<div className='process'>
  <h2>Our 6-D process</h2>
  <div className="box-container">
    <div className='desc'>
    <h6 className="box1">01.</h6>
    <h4>Discover</h4>
    <p>Discover with ECG: We uncover innovative solutions and hidden opportunities for our clients through analysis, strategic thinking, and collaboration. Together, we delve deep into business intricacies, revealing insights and new pathways to success.</p>
    </div>
    <div className='desc'>
    <h6 className="box2">02.</h6>
    <h4>Define</h4>
    <p>At ECG, we define your goals, challenges, and aspirations through detailed discussions and analysis. We clarify project scope and direction, aiming for a precise understanding of your needs.</p>
    </div>
    <div className='desc'>
    <h6 className="box3">03.</h6>
    <h4>Design</h4>
    <p>At ECG, effective solutions begin with thoughtful design. We collaborate closely with clients to craft tailored designs, leveraging our expertise to consistently exceed expectations and deliver unparalleled results.</p>
    </div>

    

  </div>
  <div className="box-container">
    <div className='desca'>
    <h6 className="box1">04.</h6>
    <h4>Develop</h4>
    <p>At ECG, we turn vision into reality with meticulous development, employing cutting-edge tech and best practices for optimal results. Our commitment to high-quality solutions exceeds industry standards.</p>
    </div>
    <div className='desca'>
    <h6 className="box2">05.</h6>
    <h4>Deploy</h4>
    <p>At ECG, we ensure seamless deployment beyond development, executing with precision to minimize disruptions and maximize efficiency. Our experienced team manages configuration for a smooth transition to production.</p>
    </div>
    <div className='desca'>
    <h6 className="box3">06.</h6>
    <h4>Deliver</h4>
    <p>At ECG, our commitment extends to final delivery. We pride ourselves on exceeding expectations, meeting deadlines, and ensuring meticulous execution from final testing to comprehensive user training.</p>
    </div>


    
  </div>
  
</div>
</div>
<div className="about-section">
      <div className="left-section">
        <img src={brand} alt="About" className="about-image" />
      </div>
      <div className="right-section">
        <h2>Why Choose Us?</h2>
        <p>
        We understand that selecting the right consulting partner is crucial for the success of your business. Here is why we believe we are the best choice for your needs 
        </p>
        <div className="white-box" ref={whiteBoxRef}>
            {/* Loop through paragraphs */}
            {paragraphs.map((item, index) => (
              <div key={index} className="heading-container">
                <div className="heading">
                  <h3>{item.heading}</h3>
                  {/* Toggle icon based on openParagraph state */}
                  <i
                    className={`fas ${
                      openParagraph === index ? 'fa-minus' : 'fa-plus'
                    }`}
                    onClick={() => toggleParagraph(index)}
                  ></i>
                </div>
                {/* Render paragraph conditionally based on openParagraph state */}
                <p
                  className={`paragraph ${
                    openParagraph === index ? 'expanded' : ''
                  }`}
                >
                  {item.content}
                </p>
              </div>
            ))}
          </div>
        </div>
        </div>

         <div className='leaders'>
          <h1>Meet Our Leaders</h1>
          </div>
           <div className="meet-our-leaders">
   <div className="leader">
     <img src={leader1} alt="John Doe" className="leader-image" />
     <div className="leader-info">
       <h3>John Doe</h3>
       <div className="icon">
       <a href="https://www.facebook.com/yourpage" target="_blank" rel="noopener noreferrer" className="social-link">
  <FaFacebook className="facebook-icon" />
</a>
<a href="https://twitter.com/youraccount" target="_blank" rel="noopener noreferrer" className="social-link">
  <i className="fa-brands fa-x-twitter twitter-icon"></i>
</a>
<a href="https://www.linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer" className="social-link">
  <FaLinkedin className="linkedin-icon" />
</a>
        
      </div>
      
     </div>
   </div>
   <div className="leader">
     <img src={leader2} alt="Jane Smith" className="leader-image" />
     <div className="leader-info">
       <h3>Jane Smith</h3>
       <div className="icon">
       <a href="https://www.facebook.com/yourpage" target="_blank" rel="noopener noreferrer" className="social-link">
  <FaFacebook className="facebook-icon" />
</a>
<a href="https://twitter.com/youraccount" target="_blank" rel="noopener noreferrer" className="social-link">
  <i className="fa-brands fa-x-twitter twitter-icon"></i>
</a>
<a href="https://www.linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer" className="social-link">
  <FaLinkedin className="linkedin-icon" />
</a>
        
      </div>
     </div>
   </div>
   <div className="leader">
     <img src={leader3} alt="Michael Johnson" className="leader-image" />
     <div className="leader-info">
       <h3>Michael Johnson</h3>
       <div className="icon">
       <a href="https://www.facebook.com/yourpage" target="_blank" rel="noopener noreferrer" className="social-link">
  <FaFacebook className="facebook-icon" />
</a>
<a href="https://twitter.com/youraccount" target="_blank" rel="noopener noreferrer" className="social-link">
  <i className="fa-brands fa-x-twitter twitter-icon"></i>
</a>
<a href="https://www.linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer" className="social-link">
  <FaLinkedin className="linkedin-icon" />
</a>
        
      </div>
     </div>
     
   </div>
   <button className="back-to-top-button" onClick={scrollToTop}>
      <FontAwesomeIcon icon={faArrowUp} style={{ color: 'white' }}/>
      </button>
   
   </div>

   {/* <div className='testimonials'>
  <Testimonial />
</div> */}
      
    </div>
  )
}

export default about;