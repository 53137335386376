import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Testimonials.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';

const testimonials = [
  {
    id: 1,
    name: 'Mohana Chandra Nath (Director)',
    subname:'Jeen Trade &amp; Exports Private Limited',
    comment: 'We can’t thank the LDTech team enough for the incredible work they did optimizing our supply chain. Now our systems run smoother than ever, even under heavy volumes. It’s incredible how much their meticulous yet creative solutions have improved workflow and reduced bottlenecks. We were initially hoping just to gain some efficiencies. But they far surpassed our expectations by fully transforming our logistics operations. ',
  },
  {
    id: 2,
    name: 'Dilip Kumar Jha (Director)',
    subname:'Vikram Pvt. Ltd',
    comment: 'We have the exciting opportunity to transform our company’s core HR operations through an integrated human resources management system that streamline and connect all aspects of HR, from talent acquisition to payroll. LDTech made it happen for us. They completely transformed how we manage human resources. Our productivity has skyrocketed thanks to streamlined operations. We’re extremely grateful to be considered as a true partner and invested such expertise into helping us reshape our workplace. ',
  },
  {
    id: 3,
    name: 'Harekrushna Jena (Commercial Manager)',
    subname:'Highland Agro Pvt. Ltd.',
    comment: 'Our organization is performing better than ever before thanks to LDTech’s solutions and dedicated support. It’s been amazing to see the results of implementing their cutting-edge ERP solutions. They completely revolutionized our workflows and processes to optimize efficiency. Plus, they paired their solutions with an incredible customer service and made sure we felt supported every step of the way. ',
  },
  {
    id: 4,
    name: 'Dilip Kumar Jha (Director)',
    subname:'Vikram Pvt. Ltd',
    comment: 'We have the exciting opportunity to transform our company’s core HR operations through an integrated human resources management system that streamline and connect all aspects of HR, from talent acquisition to payroll. LDTech made it happen for us. They completely transformed how we manage human resources. Our productivity has skyrocketed thanks to streamlined operations. We’re extremely grateful to be considered as a true partner and invested such expertise into helping us reshape our workplace.  ',
  },
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};


const AppTestimonials = () => {
    return (
      <section id="testimonials" className="testimonials-block">
        <div className="title-holder">
          <h2>What Clients Say About Us</h2>

        </div>

        <div className="slider-container">
          <Slider {...settings}>
            {testimonials.map(testimonial => (
              <div key={testimonial.id} className="testimonial-card">
                <FontAwesomeIcon icon={faQuoteLeft}  className='testimonial-image'/> 
                <h3 className="testimonial-name">{testimonial.name}</h3>
                <h4 className='testimonial-subname'>{testimonial.subname}</h4>
                <p className="testimonial-comment">{testimonial.comment}</p><br></br>
                <FontAwesomeIcon icon={faQuoteRight} className='Coma1-image' />
                
              </div>
            ))}
          </Slider>
        </div>
      </section>
    );
  };

export default AppTestimonials;
