import React from 'react'
import About from './images/finance.jpg';
import start from './images/rupees.jpg'
import './finance.css';
import iot from './images/budget.png'
import competetior from './images/forecast.png'
import resource from './images/cost.png'
import plan from './images/revenue.png'
import perform from './images/risk.png'
import risk from './images/invest.png'
import SliderService from '../SliderService';

function finance() {
  return (
    <div>
         <div className='strategy'>
      <img src={About} alt="additional" className="strategyi" />
      <div className="strategy-layer">
     
          {/* Content for the upper layer */}
          <h1>Finance</h1>
          <h4>Mastering the Numbers: Navigating Finance for Success</h4>
          <p>This section delves into financial strategies for businesses to adapt to market shifts, anticipate trends,<br /> and capitalize on opportunities, ensuring long-term sustainability and growth.</p>
        </div>
      </div>
      <div className='solution'>

      <div className="solution-content">
          <div className="solution-text">
            <h2><span className="line"></span><span className="dot"></span>Financial Solutions</h2>
            <h3>Comprehensive Financial Solutions for Business Growth and Sustainability</h3>
            <p>
            In the ever-evolving landscape of business, effective financial management stands as the cornerstone of success, serving as the bedrock upon which organizations can build resilience, seize opportunities, and thrive amidst challenges.
             At ECG, we recognize the pivotal role that sound financial strategies play in driving business growth and ensuring long-term sustainability. Our expertise lies in crafting comprehensive financial solutions meticulously tailored to the unique needs and aspirations of each client we serve.
             Through a blend of strategic foresight, analytical prowess, and industry insight, we empower businesses to navigate the complexities of today market with confidence and clarity.
            </p>
          </div>
          <div className="solution-image">
          <img src={start} alt="additional"  />
          </div>
        </div>

      </div>

      <div className='next'>
      <h2><span className='hr'><hr /></span>Our Solution <span className='hra'><hr /></span></h2>
      <div className='extended'>
      <h3>Extended Solutions for Endless possibilities</h3>
      </div>
      
      <div className='excontent'>
      <div className="ex-item">
      <img src={iot} alt="Image 1" className="section-images" />
      <h5>Budget Analysis</h5>
      <div className='para'>
      <p>Conduct a comprehensive analysis of the company&apos;s financial performance, including revenue, expenses, and cash flow. Identify areas of inefficiency, streams.</p>
      <span className='hrm'><hr /></span>
      </div>
      
    </div>
    <div className="ex-item">
    <img src={competetior} alt="Image 1" className="section-images" />
      <h5>Financial Forecasting</h5>
      <div className='paras'>
      <p>Develop accurate financial forecasts to anticipate future cash flow, revenue growth, and resource requirements. Use historical data and industry benchmarks.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={resource} alt="Image 1" className="section-images" />
      <h5>Cost Management</h5>
      <div className='parass'>
      <p>Implement cost-effective measures to optimize expenses without compromising service delivery quality. Negotiate terms with vendors, and identify reduction.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
      </div>

      <div className='excontent'>
      <div className="ex-item">
      <img src={plan} alt="Image 1" className="section-images" />
      <h5>Revenue Diversification</h5>
      <div className='para'>
      <p>
Explore opportunities to diversify revenue streams beyond core service offerings. Consider introducing additional services, or expanding into new markets to increase revenue and dependency.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={perform} alt="Image 1" className="section-images" />
      <h5>Risk Management</h5>
      <div className='paras'>
      <p>Risk management is crucial for identifying and mitigating potential threats to organizational objectives. By analyzing uncertainties and implementing strategies, negative outcomes are minimized.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={risk} alt="Image 1" className="section-images" />
      <h5>Investment Strategy</h5>
      <div className='parass'>
      <p>Develop a prudent investment strategy to optimize financial assets and generate returns. Evaluate investment opportunities based on risk tolerance, liquidity needs, and long-term goals.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
      </div>

      </div>
      <SliderService/>

      <div className='con' >
    <h2>Embark on Your Connected Journey</h2>
    <p>Embark on your connected journey with ECG, blending innovation and strategy. We specialize in guiding organizations through digital transformation, crafting robust strategies, and implementing cutting-edge solutions. Let us empower you to thrive in the evolving connectivity landscape, unlock opportunities, streamline operations, and drive sustainable growth. Join us for transformative success and redefine your business journey.</p>
    <button className="read-more">
  <a href="/contact">
    <span className="arrow">&rarr;</span>  GET STARTED
  </a>
</button>
      </div>
    </div>
  )
}

export default finance