import React from 'react'
import './brand.css';
import About from './images/brand.jpg';
import start from './images/brandsss.jpg'
import iot from './images/brand.png'
import competetior from './images/target.png'
import resource from './images/position.png'
import plan from './images/story.png'
import perform from './images/design.png'
import risk from './images/performance.png'
import SliderService from '../SliderService';

function brand() {
  return (
    <div>
        <div className='strategy'>
      <img src={About} alt="additional" className="strategyi" />
      <div className="brand-layer">
     
          {/* Content for the upper layer */}
          <h1>Branding Solutions</h1>
          <h4>Crafting Your Brand Identity: Tailored Solutions for Success</h4>
          <p>This section delves into Branding strategies for businesses to adapt to market shifts, anticipate trends,<br /> and capitalize on opportunities, ensuring long-term sustainability and growth.</p>
        </div>
      </div>
      <div className='solution'>

      <div className="solution-content">
          <div className="solution-text">
            <h2><span className="line"></span><span className="dot"></span>Branding Solutions</h2>
            <h3>Comprehensive Branding Solutions for Growth and Sustainability</h3>
            <p>
            In today&lsquo;s fast-paced and increasingly competitive business environment, the significance of branding cannot be overstated. It&lsquo;s not just about having a logo or a catchy slogan; it&lsquo;s about creating a cohesive identity that resonates with your target audience, communicates your values, and sets you apart from the competition.
             At ECG, we understand the intricacies of brand building in this dynamic landscape, and we are dedicated to helping businesses navigate the complexities of branding with confidence and clarity.
             With our expertise, creativity, and commitment to excellence, we empower our clients to build brands that not only drive growth but also foster lasting connections with their customers, ensuring sustained success in the long run.
            </p>
          </div>
          <div className="solution-image">
          <img src={start} alt="additional"  />
          </div>
        </div>

      </div>

      <div className='next'>
      <h2><span className='hr'><hr /></span>Our Solution <span className='hra'><hr /></span></h2>
      <div className='extended'>
      <h3>Extended Solutions for Endless possibilities</h3>
      </div>
      
      <div className='excontent'>
      <div className="ex-item">
      <img src={iot} alt="Image 1" className="section-images" />
      <h5>Brand Identity Development</h5>
      <div className='para'>
      <p>Define the company&apos;s brand identity, including its mission, values, personality, and visual elements. Ensure consistency across all branding materials.</p>
      <span className='hrm'><hr /></span>
      </div>
      
    </div>
    <div className="ex-item">
    <img src={competetior} alt="Image 1" className="section-images" />
      <h5>Target Audience Identification</h5>
      <div className='paras'>
      <p>Conduct market research to identify the company&apos;s target audience and understand their needs, preferences, and pain points. Develop buyer personas efforts.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={resource} alt="Image 1" className="section-images" />
      <h5>Brand Positioning Strategy</h5>
      <div className='parass'>
      <p>Determine the company&apos;s unique value proposition within the market. Differentiate the brand by highlighting strengths, expertise, and benefits offered to clients.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
      </div>

      <div className='excontent'>
      <div className="ex-item">
      <img src={plan} alt="Image 1" className="section-images" />
      <h5>Brand Messaging/Storytelling</h5>
      <div className='para'>
      <p>Craft compelling brand messages that resonate with the target audience and communicate the organization values, expertise, and impact. Emphasize authenticity and credibility.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={perform} alt="Image 1" className="section-images" />
      <h5>Brand Experience Design</h5>
      <div className='paras'>
      <p>Design a seamless and memorable brand experience for clients at every touchpoint, from the website to in-person interactions. Focus on delivering exceptional service and building lasting relationships.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={risk} alt="Image 1" className="section-images" />
      <h5>Brand Monitoring/Adaptation</h5>
      <div className='parass'>
      <p>Monitor brand perception, feedback, and market trends regularly to assess the effectiveness of branding efforts. Adapt strategies to stay relevant and maintain a competitive edge.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
      </div>

      </div>
      <SliderService/>

      <div className='con' >
    <h2>Embark on Your Connected Journey</h2>
    <p>Embark on your connected journey with ECG, blending innovation and strategy. We specialize in guiding organizations through digital transformation, crafting robust strategies, and implementing cutting-edge solutions. Let us empower you to thrive in the evolving connectivity landscape, unlock opportunities, streamline operations, and drive sustainable growth. Join us for transformative success and redefine your business journey.</p>
    <button className="read-more">
  <a href="/contact">
    <span className="arrow">&rarr;</span>  GET STARTED
  </a>
</button>
      </div>
    </div>
  )
}

export default brand