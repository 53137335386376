import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import About from './about';
import Contact from './contact';
import StrategyConsultancy from './services/strategy';
import BusinessConsultancy from './services/business';
import FinanceConsultancy from './services/finance';
import LegalConsultancy from './services/legal';
import SalesConsultancy from './services/sales';
import BrandConsultancy from './services/brand';
import MarketingConsultancy from './services/marketing';
import OperationConsultancy from './services/operation';
import ManagementConsultancy from './services/management';
import Footer from './footer';

// Components for different routes
const Clients = () => <div>Clients Content</div>;
const Careers = () => <div>Careers Content</div>;

const App = () => {
  return (
    <Router>
      <Navbar />
      <div className="container mt-3">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services/strategy" element={<StrategyConsultancy />} />
          <Route path="/services/business" element={<BusinessConsultancy />} />
          <Route path="/services/finance" element={<FinanceConsultancy />} />
          <Route path="/services/legal" element={<LegalConsultancy />} />
          <Route path="/services/sales" element={<SalesConsultancy />} />
          <Route path="/services/brand" element={<BrandConsultancy />} />
          <Route path="/services/marketing" element={<MarketingConsultancy />} />
          <Route path="/services/operation" element={<OperationConsultancy />} />
          <Route path="/services/management" element={<ManagementConsultancy />} />
        </Routes>
        <Footer />
      </div>
      
    </Router>
  );
};

export default App;
