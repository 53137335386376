import React from 'react';
import './footer.css';
import lg from './Image/logo.png';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTelegram } from '@fortawesome/free-brands-svg-icons';
 
function Footer() {
 
  return (
    <footer>
<div className="row primary">
  <div className="column about">

  <img src={lg} alt="Foolish Developer Logo" />

   <p>
   At ECG, we drive sustainable growth through innovation. Our expert team utilizes emerging tech to seize opportunities, ensuring mutual success and lasting prosperity.
  </p>

  <div className="social">
    <a href="https://www.facebook.com" target="_blank" rel="noreferrer"><i className="fa-brands fa-facebook-square"></i></a>
    <a href="https://twitter.com/hiringecgp79561" target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter twitter-icon"></i></a>
    <a href="https://www.linkedin.com/company/ecg-consultancy/" target="_blank" rel="noreferrer"><i className="fa-brands fa-linkedin"></i></a>
    {/* <a href="https://www.youtube.com" target="_blank" rel="noreferrer"><i className="fa-brands fa-youtube-square"></i></a> */}
    <a href="https://wa.me/9938014469" target="_blank" rel="noreferrer"><i className="fa-brands fa-whatsapp-square"></i></a>
</div>
</div>

<div className="loc">
  <h3>Location</h3>
   <p>A-51, Rameswar Patna,Old Town, Khorda, Bhubaneswar, Orissa, India, PIN - 751014</p>
  
</div>



<div className="column links">
<h3>Quick Links</h3>

 <ul>

  <li>
  <div className="link-container"><a href="/" ><i aria-hidden="true" className="fas fa-caret-right"></i>HOME</a></div>
  </li>
  <li>
  <div className="link-container"><a href="/about" ><i aria-hidden="true" className="fas fa-caret-right"></i>About Us</a></div>
  </li>
  <li>
  <div className="link-container"><a href="/contact" ><i aria-hidden="true" className="fas fa-caret-right"></i>Contact Us</a></div>
  </li>
  <li>
  <div className="link-container"><a href="/" ><i aria-hidden="true" className="fas fa-caret-right"></i>Services</a></div>
  </li>
 </ul>

</div>

<div className="column subscribe">
 <h3>Newsletter</h3>
  <div>
   <input type="email" placeholder="Your email id here" />
   <a className='subscribe'>
   <button className='sub'>Subscribe</button>
   </a>
  </div>

</div>

</div>

<div className="row copyright">
 
   <p>Copyright &copy; 2024 ELEVATENXT CONSULTING GUILD PRIVATE LIMITED</p>
</div>
</footer>
  );
}
export default Footer;
