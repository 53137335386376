import React from 'react'
import './sales.css';
import About from './images/sales.jpg';
import start from './images/sale.jpg'
import iot from './images/target.png'
import competetior from './images/value.png'
import plan from './images/communication.png'
import resource from './images/sales.png'
import risk from './images/approach.png'
import perform from './images/feedback.png'
import SliderService from '../SliderService';

function sales() {
  return (
    <div>
         <div className='strategy'>
      <img src={About} alt="additional" className="strategyi" />
      <div className="strategy-layer">
     
          {/* Content for the upper layer */}
          <h1>Sales Solutions</h1>
          <h4>Unlocking Sales Excellence: Essential Techniques for Growth</h4>
          <p>This section delves into sales strategies for businesses to adapt to market shifts, anticipate trends,<br /> and capitalize on opportunities, ensuring long-term sustainability and growth.</p>
        </div>
      </div>
      <div className='solution'>

      <div className="solution-content">
          <div className="solution-text">
            <h2><span className="line"></span><span className="dot"></span>Sales Solutions</h2>
            <h3>Comprehensive Sales Solutions for Growth and Sustainability</h3>
            <p>
            In the dynamic realm of sales, success hinges not only on the quality of products or services but also on the efficacy of sales strategies employed.
             At ECG, we understand that mastering the art of sales requires a multifaceted approach that encompasses strategic planning, customer engagement, and continuous adaptation to market dynamics.
             Our comprehensive sales solutions are tailored to empower businesses to navigate the complexities of the sales process with confidence and achieve sustainable growth.
             We believe that effective sales strategies are rooted in a deep understanding of market dynamics, consumer behavior, and industry trends.
              By staying attuned to changes in the marketplace and proactively adapting our strategies, we enable businesses to stay ahead of the curve and capitalize on emerging opportunities. 
            </p>
          </div>
          <div className="solution-image">
          <img src={start} alt="additional"  />
          </div>
        </div>

      </div>

      <div className='next'>
      <h2><span className='hr'><hr /></span>Our Solution <span className='hra'><hr /></span></h2>
      <div className='extended'>
      <h3>Extended Solutions for Endless possibilities</h3>
      </div>
      
      <div className='excontent'>
      <div className="ex-item">
      <img src={iot} alt="Image 1" className="section-images" />
      <h5>Targeted Customer Analysis</h5>
      <div className='para'>
      <p>Analyze customer data thoroughly to identify key demographics and preferences, effectively addressing their needs, pain points, desires, and aspirations.</p>
      <span className='hrm'><hr /></span>
      </div>
      
    </div>
    <div className="ex-item">
    <img src={competetior} alt="Image 1" className="section-images" />
      <h5>Customized Value Proposition</h5>
      <div className='paras'>
      <p>Craft a unique value proposition tailored to target customers, emphasizing how your product or service addresses their specific needs and desires effectively.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={plan} alt="Image 1" className="section-images" />
      <h5>Effective Communication</h5>
      <div className='parass'>
      <p>Implement a multi-channel strategy, combining digital marketing, social media, emails, and personalized outreach to engage prospects across the sales funnel effectively.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
      </div>

      <div className='excontent'>
      <div className="ex-item">
      <img src={resource} alt="Image 1" className="section-images" />
      <h5>Streamlined Sales Process</h5>
      <div className='para'>
      <p>
      Optimize sales processes with automation, CRM, and sales tech to enhance efficiency, ensuring a seamless customer journey, and ultimately maximizing revenue potential while minimizing costs.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={risk} alt="Image 1" className="section-images" />
      <h5>Value-added Selling Approach</h5>
      <div className='paras'>
      <p>Deliver value by offering insights, personalized solutions, and exceptional service, positioning as a trusted advisor, showcasing tangible results, and fostering long-term relationships.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
    <div className="ex-item">
    <img src={perform} alt="Image 1" className="section-images" />
      <h5>Continuous Feedback</h5>
      <div className='parass'>
      <p>
Regularly gather feedback from various sources to continuously refine sales strategy, adapt effectively, and stay aligned with evolving market trends, ensuring sustained success and growth.</p>
      <span className='hrm'><hr /></span>
      </div>
    </div>
      </div>

      </div>
      <SliderService/>

      <div className='con' >
    <h2>Embark on Your Connected Journey</h2>
    <p>Embark on your connected journey with ECG, blending innovation and strategy. We specialize in guiding organizations through digital transformation, crafting robust strategies, and implementing cutting-edge solutions. Let us empower you to thrive in the evolving connectivity landscape, unlock opportunities, streamline operations, and drive sustainable growth. Join us for transformative success and redefine your business journey.</p>
    <button className="read-more">
  <a href="/contact">
    <span className="arrow">&rarr;</span>  GET STARTED
  </a>
</button>
</div>
      </div>
    
  )
}

export default sales